import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { createRef, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Accordion, AccordionContext, Card } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import clsx from 'clsx';
import Navbar from '../components/Navbar/Navbar';
import HeroText from '../sections/HeroText/HeroText';
import Footer from '../sections/Footer/Footer';
import ArrowSingleDown from '../assets/arrow-down-single-wt.svg';
import ArrowSingleUp from '../assets/arrow-up-single-wt.svg';
import FormLoader from '../components/FormLoader/FormLoader';
import entryAnimation from '../resources/entry-animation';
import fadeInElementAnimation from '../resources/fade-in-element-animation';

const Influencer = ({ host }) => (
    <div className="single-host" key={host.name}>
        <img src={host.image[0]?.localFile.publicURL} alt={host.name} />
        <h4>{host.name}</h4>
    </div>
);

const GhHost = ({ host }) => (
    <div className="single-host">
        <img src={host.image[0]?.localFile.publicURL} alt={host.name} />
        <h4>{host.name}</h4>
        <p>{host.position1}</p>
        <p>{host.position2}</p>
    </div>
);

const CustomToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = React.useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
        <button
            type="button"
            onClick={decoratedOnClick}
            className="accordion__toggle"
            style={{
                backgroundImage: isCurrentEventKey
                    ? `url(${ArrowSingleUp})`
                    : `url(${ArrowSingleDown})`,
            }}
        >
            {children}
        </button>
    );
};

const formatDate = (date) => {
    const Month = date.getMonth() + 1;
    const Day = date.getDate();
    const Year = date.getFullYear();
    return `${Day}.${Month}.${Year}`;
};

const formatHours = (date) => {
    const hour = date.getHours();
    const minute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    return `${hour}:${minute}`;
};

const WebinarCard = ({ webinar }) => {
    const cardRef = createRef();
    fadeInElementAnimation([cardRef], '90');
    return (
        <div ref={cardRef} key={`${webinar.id}-ref`}>
            <Card key={webinar.id}>
                <CustomToggle
                    as={Card.Header}
                    eventKey={webinar.id}
                    callback={() => {
                        setTimeout(() => {
                            cardRef.current.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                            });
                        }, 300);
                    }}
                >
                    <h3 className="toggle-header webinars-list__header">
                        {webinar.name}
                    </h3>
                    {formatDate(webinar.start_date)}
                    {' | '}
                    {formatHours(webinar.start_date)}
                    -
                    {formatHours(webinar.end_date)}
                </CustomToggle>
                <Accordion.Collapse eventKey={webinar.id}>
                    <Card.Body className="webinar-info">
                        <div className="webinar-info__description">
                            <h3>Opis</h3>
                            <p>{webinar.long_description}</p>
                            <a
                                href="#webinars_form"
                                className="btn btn--transparent-white d-none d-lg-inline-flex"
                            >
                                Zapisz się
                            </a>
                        </div>
                        <div className="webinar-info__hosts">
                            {webinar.hosts && (
                                <div className="hosts-wrapper">
                                    <h3>Prowadzący</h3>
                                    {webinar.hosts.map((host) => (
                                        <GhHost host={host} key={host.name} />
                                    ))}
                                </div>
                            )}
                            {webinar.influencers && webinar.influencers.length > 0 && (
                                <div className="hosts-wrapper">
                                    <h3>Influencerzy</h3>
                                    {webinar.influencers.map((host) => (
                                        <Influencer host={host} key={host.name} />
                                    ))}
                                </div>
                            )}
                        </div>
                        <a
                            href="#webinars_form"
                            className="btn btn--transparent-white d-inline-flex d-lg-none"
                        >
                            Zapisz się
                        </a>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </div>
    );
};

const WebinaryPage = () => {
    const navbarBreakpointRef = useRef();
    const data = useStaticQuery(query);
    const scriptUrl = 'https://r5c8k9e7pe.execute-api.us-east-1.amazonaws.com'
    + '/prod-aws/flotiq-forms/webinar_registrations-uXiGFmq2z';
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDateFormatted, setIsDateFormatted] = useState(false);
    const [webinars, setWebinars] = useState();
    const mainRef = useRef(null);
    entryAnimation(mainRef);
    useEffect(() => {
        if (!isDateFormatted) {
            const formattedWebinars = data.allWebinars.nodes.map((webinar) => {
                const formattedWebinar = webinar;
                const [day1, month1, year1, time1] = [
                    ...webinar.start_date.split(' ')[0].split('.'),
                    webinar.start_date.split(' ')[1],
                ];
                const [day2, month2, year2, time2] = [
                    ...webinar.end_date.split(' ')[0].split('.'),
                    webinar.end_date.split(' ')[1],
                ];
                const [hour1, minute1] = time1.split(':');
                const [hour2, minute2] = time2.split(':');
                formattedWebinar.start_date = new Date(
                    year1,
                    month1 - 1,
                    day1,
                    hour1,
                    minute1,
                );
                formattedWebinar.end_date = new Date(
                    year2,
                    month2 - 1,
                    day2,
                    hour2,
                    minute2,
                );
                return formattedWebinar;
            });
            const currentWebinars = formattedWebinars
                .filter((webinar) => webinar.start_date > new Date())
                .sort((web1, web2) => web1.start_date - web2.start_date);
            setWebinars(currentWebinars);
            setIsDateFormatted(true);
        }
    }, [data.allWebinars.nodes, isDateFormatted]);

    const getLength = (start, end) => `${(end.getTime() - start.getTime()) / (1000 * 60)} minut`;

    const handleOnLoad = () => {
        setIsLoaded(true);
    };

    const handleScriptInject = ({ scriptTags }) => {
        if (scriptTags) {
            const scriptTag = scriptTags[0];
            scriptTag.onload = handleOnLoad;
        }
    };

    const titleSectionRef = useRef(null);
    const formTitle = useRef(null);
    const formWrapperRef = useRef(null);
    fadeInElementAnimation([titleSectionRef, formTitle, formWrapperRef]);
    return (
        <main ref={mainRef}>
            <div ref={navbarBreakpointRef} className="ref-navbar" />
            <Navbar breakpointRef={navbarBreakpointRef} />
            <Helmet
                onChangeClientState={(newState, addedTags) => handleScriptInject(addedTags)}
            >
                <title>WEBINARY | GetHero</title>
                <link
                    rel="stylesheet"
                    href="https://flotiq-form-generator-styles.s3.amazonaws.com/styles.css"
                />
                <script src={scriptUrl} async />
            </Helmet>
            <HeroText
                header={data.allWebinarPage.nodes[0].header}
                firstParagraph={data.allWebinarPage.nodes[0].first_hero_paragraph}
                secondParagraph={data.allWebinarPage.nodes[0].second_hero_paragraph}
                buttonText1={data.allWebinarPage.nodes[0].first_button_text}
                buttonLink1="#webinars"
                buttonText2={data.allWebinarPage.nodes[0].second_button_text}
                buttonLink2="#webinars_form"
            />
            <div id="webinars" className="webinars-container">
                <div ref={titleSectionRef} className="upcoming-webinars">
                    <h4 className="upcoming-webinars__header">
                        <span>Najbliższe</span>
                        {' '}
                        webinary:
                    </h4>
                    <div
                        className={clsx(
              webinars?.length
                  ? 'upcoming-webinars__description'
                  : 'upcoming-webinars__no-webinars',
                        )}
                    >
                        {webinars?.length > 0 ? (
                            <div className="description-paragraph" key={webinars[0].id}>
                                <div className="description-paragraph__webinar-name">
                                    {webinars[0].name}
                                </div>
                                <div className="description-paragraph__webinar-info">
                                    {webinars[0].short_description}
                                </div>
                                <div className="description-paragraph__webinar-duration">
                                    Czas trwania:
                                    {' '}
                                    {getLength(webinars[0].start_date, webinars[0].end_date)}
                                </div>
                            </div>
                        ) : (
                            <h1>W najbliższym czasie nie odbywają się żadne webinary.</h1>
                        )}
                        {webinars?.length > 1 && (
                            <div className="description-paragraph" key={webinars[1].id}>
                                <div className="description-paragraph__webinar-name">
                                    {webinars[1].name}
                                </div>
                                <div className="description-paragraph__webinar-info">
                                    {webinars[1].short_description}
                                </div>
                                <div className="description-paragraph__webinar-duration">
                                    Czas trwania:
                                    {' '}
                                    {getLength(webinars[1].start_date, webinars[1].end_date)}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/* https://react-bootstrap.github.io/components/accordion/ */}

                {webinars?.length > 0 && (
                    <div className="webinars-list">
                        <Accordion defaultActiveKey={webinars && webinars[0]?.id}>
                            {webinars?.map((webinar) => (
                                <WebinarCard webinar={webinar} key={webinar.id} />
                            ))}
                        </Accordion>
                    </div>
                )}
            </div>
            {webinars?.length > 0 && (
                <div
                    ref={formTitle}
                    id="webinars_form"
                    className="webinars-registration"
                >
                    <h4 className="webinars-registration__header">
                        <span>Zapraszamy </span>
                        do zapisywania się poprzez nasz formularz.
                    </h4>
                    <div ref={formWrapperRef} className="webinars-registration__form">
                        <span className="required-info">*Obowiązkowe</span>
                        {isLoaded ? <flotiq-form /> : <FormLoader setYellow />}
                    </div>
                </div>
            )}
            <Footer />
        </main>
    );
};

const query = graphql`
  query WebinarSettings {
    allWebinarPage {
      nodes {
        second_hero_paragraph
        first_hero_paragraph
        header
        first_button_text
        second_button_text
      }
    }
    allWebinars(sort: { fields: id, order: ASC }) {
      nodes {
        id
        name
        short_description
        start_date
        long_description
        end_date
        hosts {
          name
          position1
          position2
          image {
            localFile {
              publicURL
            }
          }
        }
        influencers {
          name
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default WebinaryPage;
